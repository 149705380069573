import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from '../components/layout'
import PaginationContainer from '../components/paginationContainer'

import ContentWrapper from '../styles/contentWrapper'
import TitleWrapper from '../styles/titleWrapper'
import OffsetWrapper from '../styles/offsetWrapper'
import PostsList from '../styles/postsList'



const BlogIndexTemplate = ({ pageContext }) => {

  let next = null
  let prev = null
  const { currentPage, numPages } = pageContext


  if (currentPage > 1 && currentPage === numPages) {
    next = null
  } else if (currentPage === 1 || currentPage > 1) {
    next = {
      title: `go to posts page ${currentPage + 1}`,
      route: `/blog/${currentPage + 1}`
    }
  }


  if (currentPage === 1 && currentPage < numPages) {
    prev = null
  } else if (currentPage <= numPages) {

    const page = currentPage - 1 === 1 ? '' : currentPage - 1 

    prev = {
      title: `go to posts page ${currentPage - 1}`,
      route: `/blog/${page}`
    }
  }

  const posts = pageContext.nodes.map(node => {
    return (
      <li key={node.contentful_id}>
        <a href={`/blog/${node.slug}`}>
          <p className="post-title">{node.title}</p>
          {documentToReactComponents(node.excerpt.json)}
        </a>
      </li>
    )
  })

  return (
    <Layout>
      <ContentWrapper>
        <TitleWrapper>
          <h1>Blog</h1>
          <PaginationContainer next={next} prev={prev} />
        </TitleWrapper>
      <OffsetWrapper>
        <PostsList>
          {posts}
        </PostsList>
      </OffsetWrapper>
      </ContentWrapper>
    </Layout>
  )
}

export default BlogIndexTemplate





// const BlogIndexTemplate = ({ pageContext, data }) => {

//   return (
//     <Layout>
//       <BlogPostsWrapper>
//         {
//           // render content from contentful
//           documentToReactComponents(pageContext.content)
//         }
//       </BlogPostsWrapper>
//       <StaticQuery 
//         query={graphql`
//           query blogIndexQuery {
//             allContentfulPageContent(
//               filter: {
//                 contentType: {
//                   eq: "Blog Post"
//                 }
//               },
//               sort: {
//                 fields: createdAt, 
//                 order: DESC
//               }
//             ) {
//               edges {
//                 node {
//                   contentful_id
//                   title
//                   slug
//                 }
//               }
//             }
//           }
//         `} 
//         render={({ allContentfulPageContent }) => {
//           const posts = allContentfulPageContent.edges.map(({ node }) => {
//             return (
//               <li key={node.contentful_id}>
//                 <a href={`/blog/${node.slug}`}>
//                   <p className="post-title">{node.title}</p>
//                   <p>This is a brief excerpt of the post</p>
//                 </a>
//               </li>
//             )
//           })

//           return (
//             <>
//             <PostsList className="posts-list">
//               {posts}
//             </PostsList>
//             </>
//           )
//         }}
//       />
//     </Layout>
//   )
// }

// export default BlogIndexTemplate